/**
 * Created by Upwardstech on 12/17/15.
 */

jQuery(document).ready(function($) {

// more information instructors
  $('#more-information .heading span').click( function(){
      $(this).parents('#more-information').find('.content-more').slideToggle( "slow" );
      $( this).find('i').toggleClass('arrow-down arrow-up');
    }
  );

  //Check browser
  var isChromium = window.chrome,
    vendorName = window.navigator.vendor,
    isOpera = window.navigator.userAgent.indexOf("OPR") > -1;
  if(isChromium !== null && isChromium !== undefined && vendorName === "Google Inc." && isOpera === false) {
    // is Google chrome
    $('body').addClass('chrome');
  }

// more instructors
  $('.single-instructors #block-instructors .row-instructors').slick({
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
  });

  $('#teaching-faculty #block-instructors .row-instructors').slick({
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
  });

// home events
  $('#home-events .row-events').slick({
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
          // settings: "slick"
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
});

  //sub menu by click
  $('<span class="fa fa-chevron-down main-menu-arrowdown"></span>').insertBefore('.nav-primary .nav .sub-menu');
  // $('.nav-primary .nav .menu-item-has-children a').append('<span class="fa fa-chevron-down main-menu-arrowdown"></span>');

  $('.main-menu-arrowdown').click(function() {
    // Close all open sub-menus
    // $('.sub-menu.display-menu').removeClass('display-menu');
    // $('.main-menu-arrowdown.rotate-arrow').removeClass('rotate-arrow');

    // Toggle the sub-menu of the clicked arrow
    $(this).siblings('.sub-menu').toggleClass('display-menu');
    $(this).toggleClass('rotate-arrow');
  });

  if ($(window).width() >= 770) {
    $('li.menu-item-has-children').mouseenter(function () {
      $(this).find('.sub-menu').addClass('display-menu');
    });

    $('li.menu-item-has-children').mouseleave(function () {
      $(this).find('.sub-menu').removeClass('display-menu');
    });
  }

  //close menu
  $('.open-menu').click(function () {
    $('.nav-primary').removeClass('menu-all');
    $('.navbar-collapse').collapse('show');
  });

  $('.close-menu').click(function () {
    $('.nav-primary').addClass('menu-all');
    $('.navbar-collapse').collapse('hide');
  });

  $('.nav li a').click(function () {
    $('.nav-primary').addClass('menu-all');
    $('.navbar-collapse').collapse('hide');
  });

  $("html").on("click",function(e) {
    //$('.navbar-collapse').collapse('hide');
  });
  $("#search_form_text").on("click",function(e) {
    e.stopPropagation();
  });

  //change value

  $('.gform_widget .gform_button').val('Send');

  //placeholder

  $('.list .gfield_list_49_cell1 input[type=text]').each(function() {
    $(this).attr('placeholder', 'School/style');
  });
  $('.list .gfield_list_49_cell2 input[type=text]').each(function() {
    $(this).attr('placeholder', 'Teacher(s)');
  });
  $('.list .gfield_list_49_cell3 input[type=text]').each(function() {
    $(this).attr('placeholder', 'Number of years');
  });

  $('.list .gfield_list_52_cell1 input[type=text]').each(function() {
    $(this).attr('placeholder', 'School/style');
  });
  $('.list .gfield_list_52_cell2 input[type=text]').each(function() {
    $(this).attr('placeholder', 'Teacher(s)');
  });
  $('.list .gfield_list_52_cell3 input[type=text]').each(function() {
    $(this).attr('placeholder', 'Number of years');
  });

  var h = $('#classes-slider-wrapper').height();

  var width = $(window).width();
  if (width<=768) {
    $('#classes-slider-nav-ul').css('min-height',h);
  }
  $( window ).resize(function() {
    width = $(window).width();
    console.log();
    if (width<=768) {
      $('#classes-slider-nav-ul').css('min-height',h);
    }
  });
});
